import { graphql } from 'gatsby'

import { isBrowser } from '../utils/index'
import { getCurrencyCode, getPrice, getProductId } from '../utils/shopify'

const name = 'viewProduct'

export type ViewProductEvent = {
  id: string
  name: string
  type: 'product_group'
  category: string
  value: number
  currency: string
}

const viewProduct = (result: Queries.ViewProductEventFragment) => {
  if (!isBrowser || !result.shopify) return

  const { shopify } = result

  const event = new CustomEvent<ViewProductEvent>(name, {
    detail: {
      id: getProductId(shopify),
      name: shopify.title,
      type: 'product_group',
      category: shopify.productType,
      value: getPrice(shopify),
      currency: getCurrencyCode(shopify)
    }
  })

  console.info(`🎯 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<ViewProductEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export const query = graphql`
  fragment ViewProductEvent on PrismicProduct {
    shopify {
      title
      productType
      ...ShopifyId
      ...ShopifyPrice
      ...ShopifyCurrency
    }
  }
`
export default viewProduct
