import { isBrowser } from '../../utils/index'

// AWS-274 - Temporary event to check UI

const name = 'clickSelectEmployee'

const clickSelectEmployee = () => {
  if (!isBrowser) return

  const event = new CustomEvent(name)

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default clickSelectEmployee
