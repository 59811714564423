import type { Partner } from '../components/icon/partner'
import type { Language } from './language'
import { br, ch, cn, eu } from './shops'

export type Instance = 'CH' | 'EU' | 'BR' | 'CN'

export type Configuration = {
  canonical: Partial<Record<Language, Instance>> & { default: Instance }
  ipstack: {
    accessToken: string
  }
  elfsight: {
    instagram: string
  }
  emailjs: {
    userId: string
    serviceId: string
    templateId: string
  }
  storageKey: {
    gdpr: { key: string; ttl: number }
    utm: { key: string; ttl: number }
    countryRedirect: { key: string; ttl: number }
    productPartner: { key: string; ttl: number }
    shopify: string
  }
  urlFlags: {
    shopify: {
      discountCode: string
    }
    booking: {
      product: string
      variant: string
      location: string
    }
  }
  instances: Instance[]
  redirect: {
    fallbackInstance: Instance
    flag: string
  }
  general: {
    map: { apikey: string }
    articles: {
      excerptLength: number
    }
  }
  shop: {
    countries: string[]
    instance: Instance
    sections: {
      shop: boolean
      treatments: boolean
    }
    lang: {
      main: Language
      enabled: Language[]
    }
    meta: {
      [key in Language]?: { title: string; description: string; keywords: string[] }
    }
    partners: Partner[]
    newsletter: string
    scripts: {
      baidu?: string
      getklar?: string
      gtm?: string
      hotjar?: string
      facebook?: string
      pinterest?: string
      tiktok?: string
      kameeleon?: string
      klaviyo?: string
      skimLinks?: string
    }
    shopifyClient: {
      domain: string
      accessToken: string
    }
    stamped: {
      apiKeyPublic: string
      storeUrl: string
    }
    url: string
  }
}

export const ShopInstance: Instance = process.env.GATSBY_SHOP_INSTANCE
export const isCurrentInstance = (instance: Instance | string | null): boolean => {
  return ShopInstance.toLowerCase() === instance?.toLowerCase()
}

export function configuration(instance = ShopInstance) {
  switch (instance) {
    case 'BR':
      return br
    case 'CH':
      return ch
    case 'CN':
      return cn
    case 'EU':
      return eu
    default:
      throw new Error('Missing Shop Configuration')
  }
}
