import { isBrowser } from '../utils/index'

const name = 'removeFromCart'

export type RemoveFromCartEvent = {
  items: { id: string; price: number; name: string; quantity: number }[]
  value: number
  currency: string
}

const removeFromCart = (detail: RemoveFromCartEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<RemoveFromCartEvent>(name, { detail })

  console.info(`🎯 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<RemoveFromCartEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default removeFromCart
