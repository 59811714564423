import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { graphql } from 'gatsby'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { twJoin } from 'tailwind-merge'

import useSettings from '../../hooks/useSettings'
import { updateConsent, useGDPR, useGDPRDispatch } from '../../provider/gdpr'
import Button from '../button'
import Link from '../link'
import RichText from '../richText'
import Transition from '../transition'
import { isBrowser } from './../../utils'

type Props = { result: Queries.CookieFragment }

const MinimalBanner: React.FC<Props> = ({ result: { cookiebanner_title: title, cookiebanner_content: content } }) => {
  const state = useGDPR()
  const dispatch = useGDPRDispatch()

  const closeModal = useCallback(() => {
    if (updateConsent) updateConsent(dispatch, { necessary: true, statistic: true })
  }, [dispatch])

  return (
    <Transition show={!state} as="div">
      <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
        <div className="fixed inset-0 bg-black-40" />
        <div className="fixed inset-0 flex items-end justify-center !pb-0 md:p-4">
          <DialogPanel className="md:max-h-unset p-b-md-fluid relative max-h-[60dvH] w-screen max-w-screen-xxl overflow-y-scroll rounded bg-white-default no-scrollbar">
            <DialogTitle
              as="div"
              className="sticky z-20 flex justify-between border-b border-b-black-10 bg-white-default py-5 pl-5 pr-3 lg:pl-10 lg:pr-6"
            >
              <div className="flex">
                <div className="text-sub_lg font-bold">{title}</div>
              </div>
              <button
                type="button"
                onClick={closeModal}
                name="close"
                className="base-transition-150 absolute right-4 top-4 flex h-10 w-10 items-center justify-center rounded-sm border border-transparent text-blue-primary hover:border-sky-140 hover:text-sky-140"
              >
                <XMarkIcon className="block h-5 w-5" />
              </button>
            </DialogTitle>
            <div className="m-t-md-fluid relative flex px-5 lg:px-10">
              <RichText data={content?.richText} />
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  )
}

const CompleteBanner: React.FC<Props> = ({
  result: {
    cookiebanner_title: title,
    cookiebanner_content: content,
    cookiebanner_privacy: privacy,
    cookiebanner_impressum: impressum
  }
}) => {
  const { t } = useTranslation()
  const state = useGDPR()
  const dispatch = useGDPRDispatch()
  const checkbox = useRef<HTMLInputElement | null>(null)

  const acceptAll = useCallback(() => {
    if (updateConsent) updateConsent(dispatch, { necessary: true, statistic: true })
  }, [dispatch])

  const accept = useCallback(() => {
    if (!checkbox.current || !updateConsent) return

    updateConsent(dispatch, { necessary: true, statistic: checkbox.current.checked })
  }, [dispatch])

  return (
    <Transition show={!state} as="div">
      <Dialog as="div" className="relative z-[99]" onClose={accept}>
        <div className="fixed inset-0 bg-black-40" />
        <div className="fixed inset-0 flex items-end justify-center md:items-center md:p-4">
          <Dialog.Panel className="md:max-h-unset relative max-h-[60dvH] w-screen overflow-y-scroll rounded bg-white-default no-scrollbar md:w-[560px]">
            <Dialog.Title
              as="div"
              className="sticky z-20 flex justify-between border-b border-b-black-10 bg-white-default py-5 pl-5 pr-3 lg:pl-10 lg:pr-6"
            >
              <div className="flex">
                <div className="text-sub_lg font-bold">{title}</div>
              </div>
              <button type="button" onClick={accept}>
                <XMarkIcon className="block h-5 w-5" />
              </button>
            </Dialog.Title>
            <div className="m-t-md-fluid m-b-md-fluid relative px-5 lg:px-10">
              <div>
                <RichText data={content?.richText} />
              </div>
              <div className="m-t-xs-fluid empty:hidden">
                {impressum && (
                  <Link link={impressum} className="mr-5">
                    {t('cookieBanner.impressum', 'Impressum')}
                  </Link>
                )}
                {privacy && (
                  <Link link={privacy} className="mr-5">
                    {t('cookieBanner.privacy', 'Privacy Policy')}
                  </Link>
                )}
              </div>
              <div className="m-t-md-fluid m-b-md-fluid">
                <span className="mr-5">
                  <input
                    className={twJoin(
                      'bg-white -mt-1.5 mr-2 h-6 w-6 rounded-sm border border-black-40',
                      'checked:bg-black-40 checked:hover:bg-black-50 focus:outline-1 focus:outline-black-40 focus:ring-0',
                      'checked:focus:bg-black-40 checked:focus:outline-1 checked:focus:outline-black-40'
                    )}
                    type="checkbox"
                    name="necessary"
                    disabled
                    checked
                  />
                  <label htmlFor="necessary">{t('cookieBanner.necessary', 'Necessary')}</label>
                </span>
                <span className="mr-5">
                  <input
                    ref={checkbox}
                    className={twJoin(
                      'bg-white -mt-1.5 mr-2 h-6 w-6 rounded-sm border border-black-40',
                      'checked:bg-black-40 checked:hover:bg-black-50 focus:outline-1 focus:outline-black-40 focus:ring-0',
                      'checked:focus:bg-black-40 checked:focus:outline-1 checked:focus:outline-black-40'
                    )}
                    type="checkbox"
                    name="statistic"
                  />
                  <label htmlFor="statistic">{t('cookieBanner.performance', 'Performance')}</label>
                </span>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <Button type="secondary" onClick={accept}>
                  {t('cookieBanner.confirmSelection', 'Confirm selection')}
                </Button>
                <Button type="primary" onClick={acceptAll}>
                  {t('cookieBanner.selectAll', 'Select all')}
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  )
}

const Cookie: React.FC<{ uid: string | null }> = ({ uid }) => {
  const settings = useSettings()
  const state = useGDPR()

  if (!isBrowser) return null

  // No CookieBanner on Privacy or Impressum
  if (uid && (uid === settings.cookiebanner_impressum?.uid || uid === settings.cookiebanner_privacy?.uid)) return null

  // GDPRState
  if (state) return null

  switch (settings.cookiebanner_type) {
    case 'Minimal':
      return <MinimalBanner result={settings} />
    case 'Complete':
      return <CompleteBanner result={settings} />
    default:
      return null
  }
}

export const query = graphql`
  fragment Cookie on PrismicDomainData {
    cookiebanner_type
    cookiebanner_title
    cookiebanner_content {
      ...RichText
    }
    cookiebanner_impressum {
      ...Link
    }
    cookiebanner_privacy {
      ...Link
    }
  }
`

export default Cookie
