import React from 'react'

import Facebook from './_tracking/facebook'
import Getklar from './_tracking/getklar'
import GTM from './_tracking/gtm'
import Hotjar from './_tracking/hotjar'
import Kameeleon from './_tracking/kameeleon'
import Klaviyo from './_tracking/klaviyo'
import Pinterest from './_tracking/pinterest'
import SkimLinks from './_tracking/skimLinks'
import Tiktok from './_tracking/tiktok'

const Tracking: React.FC = () => {
  return (
    <>
      <GTM />
      <Facebook />
      <Tiktok />
      <Kameeleon />
      <Pinterest />
      <Klaviyo />
      <Hotjar />
      <Getklar />
      <SkimLinks />
    </>
  )
}

export default Tracking
