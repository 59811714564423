import { graphql } from 'gatsby'
import React from 'react'
import { twJoin } from 'tailwind-merge'

import Link from '../../../link'
import { Icon } from '../../header'
import Cart from '../cart'

type Props = {
  items?: Queries.MenuRestFragment['items']
  closeNavigation: () => void
  className?: string
}

const NavRest: React.FC<Props> = ({ items, closeNavigation, className = '' }) => {
  return (
    <div className={className}>
      {items?.map((item, index) => {
        const isLast = index === items.length - 1

        if (isLast) return <Cart key={index} item={item} withTitle={true} />

        if (!item?.nav_link) return null

        return (
          <Link
            key={index}
            link={item.nav_link}
            onClick={() => closeNavigation()}
            className={twJoin(
              'm-r-sm-fluid base-transition-100 flex justify-start border-b-0 text-link_sm',
              'hover-focus:border-b-blue-primary hover-focus:text-blue-primary hover-focus:child-span:border-blue-primary'
            )}
          >
            {item.nav_icon && <Icon name={item.nav_icon} />}
            <span className="base-transition-200 border-b border-transparent pl-1">{item.nav_title}</span>
          </Link>
        )
      })}
    </div>
  )
}

export const query = graphql`
  fragment MenuRest on PrismicDomainData {
    items {
      nav_icon
      nav_title
      nav_link {
        ...Link
      }
    }
  }
`

export default NavRest
