import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

export type TImage = { gatsbyImageData: Record<string, unknown> | null; alt: string | null } | null

type Props = {
  image: TImage
  className?: string
  alt?: string
  lazy?: boolean
}

const Image: React.FC<Props> = ({ image, alt, className, lazy = true }) => {
  if (!image?.gatsbyImageData) return null

  return (
    <GatsbyImage
      image={image.gatsbyImageData as unknown as IGatsbyImageData}
      alt={image.alt ?? alt ?? ''}
      loading={lazy ? 'lazy' : 'eager'}
      className={className}
    />
  )
}

export default Image
