import Config from '../config/index'
import { getWithExpiry, isBrowser, setWithExpiry } from './index'

const { key: storageKey, ttl } = Config().storageKey.utm

type UTM = {
  source?: string
  medium?: string
  campaign?: string
  term?: string
  content?: string
  gclid?: string
  fbclid?: string
  date: string
}

const saveUTMTags = () => {
  if (!isBrowser) return

  const params = new URL(window.location.href).searchParams

  const source = params.get('utm_source')
  const medium = params.get('utm_medium')
  const campaign = params.get('utm_campaign')
  const term = params.get('utm_term')
  const content = params.get('utm_content')
  const gclid = params.get('gclid')
  const fbclid = params.get('fbclid')

  const tags = {
    ...(source ? { source } : null),
    ...(medium ? { medium } : null),
    ...(campaign ? { campaign } : null),
    ...(term ? { term } : null),
    ...(content ? { content } : null),
    ...(gclid ? { gclid } : null),
    ...(fbclid ? { fbclid } : null)
  }

  if (Object.keys(tags).length === 0) return

  setWithExpiry(storageKey, { ...tags, date: new Date().toUTCString() }, ttl)
}

const addUTMTagsToUrl = (url: string | null) => {
  const tags = getWithExpiry<UTM>(storageKey) ?? null

  if (!tags || !url) return url

  try {
    const _url = new URL(url)

    if (tags.source) _url.searchParams.set('utm_source', tags.source)
    if (tags.medium) _url.searchParams.set('utm_medium', tags.medium)
    if (tags.campaign) _url.searchParams.set('utm_campaign', tags.campaign)
    if (tags.term) _url.searchParams.set('utm_term', tags.term)
    if (tags.content) _url.searchParams.set('utm_content', tags.content)
    if (tags.gclid) _url.searchParams.set('gclid', tags.gclid)
    if (tags.fbclid) _url.searchParams.set('fbclid', tags.fbclid)

    return _url.toString()
  } catch (_error) {
    return url
  }
}

const getUTMTags = () => {
  return getWithExpiry<UTM>(storageKey)
}

export { addUTMTagsToUrl, getUTMTags, saveUTMTags }
