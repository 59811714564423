module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alpine White | Teeth Whitening Strips | Bleaching ","short_name":"Alpine White | Teeth Whitening Strips | Bleaching ","description":"Selbst für ein strahlendes Lächeln sorgen. Alpine White bietet ein komplettes Set für Reinigung, Bleaching und Pflege. Entdecke unsere Whitening Strips.","background_color":"#ffffff","theme_color":"#316BAB","display":"fullscreen","icon":"src/images/favicon.png","start_url":"/","lang":"de","localize":[{"start_url":"/en/","lang":"en","name":"Alpine White | Teeth Whitening Strips | Bleaching","short_name":"Alpine White | Teeth Whitening Strips | Bleaching","description":"Give yourself a radiant smile with white teeth. Alpine White provides a complete kit for cleaning, bleaching and care. Discover our Whitening Strips."},{"start_url":"/fr/","lang":"fr","name":"Alpine White | Strips Blanchiment Dentaire | Blanchiment ","short_name":"Alpine White | Strips Blanchiment Dentaire | Blanchiment ","description":"Retrouvez un sourire rayonnant et des dents blanches. Alpine White propose une série de produits pour le nettoyage, le blanchiment et le soin des dents."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6920960760db51bf20b05a78e25626d4"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
