import { isBrowser } from '../utils/index'

const name = 'pageView'

export type PageViewEvent = {
  id: string
  name: string
  category: string
}
const pageView = (detail: PageViewEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent(name, { detail })

  console.info(`🎯 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<PageViewEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default pageView
