import { Script } from 'gatsby'
import React from 'react'

import Config from '../../config'
import { useGDPR } from '../../provider/gdpr'

const Getklar: React.FC = () => {
  const consent = useGDPR()
  const id = Config().shop.scripts.getklar

  if (!id || !consent?.statistic) return null

  return (
    <Script strategy="idle" id="getklar-config">
      {`
          (function () {
            var a = document.createElement('script');
            (a.type = 'text/javascript'),
            (a.async = !0),
            (a.src = 'https://${id}.alpinewhite.com/javascript/script.js'),
            document.getElementsByTagName('script')[0].appendChild(a);
          })();
        `}
    </Script>
  )
}

export default Getklar
