import React from 'react'

// Original SVG imports
import AmexSVG from '../../assets/payment/amex.svg'
import ApplePaySVG from '../../assets/payment/applePay.svg'
import GooglePaySVG from '../../assets/payment/googlePay.svg'
import KlarnaSVG from '../../assets/payment/klarna.svg'
import MastercardSVG from '../../assets/payment/mastercard.svg'
import PaypalSVG from '../../assets/payment/paypal.svg'
import PixSVG from '../../assets/payment/pix.svg'
import TwintSVG from '../../assets/payment/twint.svg'
import VisaSVG from '../../assets/payment/visa.svg'

// Interface for SVG wrapper components
interface SVGWrapperProps {
  width?: string
  height?: string
  className?: string
}

// SVG Wrapper Components
const AmexIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <AmexSVG width={width} height={height} className={className} />
)

const ApplePayIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <ApplePaySVG width={width} height={height} className={className} />
)

const GooglePayIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <GooglePaySVG width={width} height={height} className={className} />
)

const KlarnaIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <KlarnaSVG width={width} height={height} className={className} />
)

const MastercardIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <MastercardSVG width={width} height={height} className={className} />
)

const PaypalIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <PaypalSVG width={width} height={height} className={className} />
)

const PixIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <PixSVG width={width} height={height} className={className} />
)

const TwintIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <TwintSVG width={width} height={height} className={className} />
)

const VisaIcon: React.FC<SVGWrapperProps> = ({ width = '34', height = '24', className = '' }) => (
  <VisaSVG width={width} height={height} className={className} />
)

type Icon = 'Amex' | 'ApplePay' | 'GooglePay' | 'Klarna' | 'Mastercard' | 'Paypal' | 'Twint' | 'Visa' | 'Pix'

interface PaymentIconProps {
  icon: Icon | string | null
  width?: string
  height?: string
  className?: string
}

const PaymentIcon: React.FC<PaymentIconProps> = ({
  icon,
  width = '34',
  height = '24',
  className = 'border rounded-md border-black-hex_20'
}) => {
  if (!icon) return null

  switch (icon) {
    case 'Amex':
      return <AmexIcon width={width} height={height} className={className} />
    case 'ApplePay':
      return <ApplePayIcon width={width} height={height} className={className} />
    case 'GooglePay':
      return <GooglePayIcon width={width} height={height} className={className} />
    case 'Klarna':
      return <KlarnaIcon width={width} height={height} className={className} />
    case 'Mastercard':
      return <MastercardIcon width={width} height={height} className={className} />
    case 'Paypal':
      return <PaypalIcon width={width} height={height} className={className} />
    case 'Twint':
      return <TwintIcon width={width} height={height} className={className} />
    case 'Visa':
      return <VisaIcon width={width} height={height} className={className} />
    case 'Pix':
      return <PixIcon width={width} height={height} className={className} />
    default:
      return null
  }
}

export default PaymentIcon
