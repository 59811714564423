import { graphql } from 'gatsby'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import Client, { Cart, Checkout } from 'shopify-buy'

import Config from '../config/'
import addToCardEvent from '../events/addToCart'
import removeFromCardEvent from '../events/removeFromCart'
import { get, isBrowser, remove, set } from '../utils'
import { getCurrencyCode, getPrice, getProductId } from '../utils/shopify'

const client = Client.buildClient({
  apiVersion: '2023-07',
  storefrontAccessToken: Config().shop.shopifyClient.accessToken,
  domain: Config().shop.shopifyClient.domain
})

const storageKey = Config().storageKey.shopify

// Types _______________________________________________________________________________________________________________

export type State = {
  client: Client
  status: 'adding' | 'removing' | 'updating' | null
  checkout?: Checkout
  cart?: Cart
}

type Action = { type: 'set_checkout'; payload: Checkout } | { type: 'set_status'; payload: State['status'] }

// Helper ______________________________________________________________________________________________________________

const createCheckout = async (client: Client, dispatch: React.Dispatch<Action>): Promise<Checkout | undefined> => {
  if (!isBrowser) return undefined
  const existingCheckoutID = get<string>(storageKey)

  if (existingCheckoutID) {
    try {
      const checkout = await client.checkout.fetch(existingCheckoutID)

      if (checkout.completedAt) {
        throw new Error('Cart has already been purchased.')
      }

      set<string>(storageKey, checkout.id)

      dispatch({ type: 'set_checkout', payload: checkout })
      return checkout
    } catch (e) {
      remove(storageKey)

      return createCheckout(client, dispatch)
    }
  } else {
    const checkout = await client.checkout.create()

    set<string>(storageKey, checkout.id)
    dispatch({ type: 'set_checkout', payload: checkout })
    return checkout
  }
}

// _____________________________________________________________________________________________________________________

const addDiscount = async (state: State, dispatch: React.Dispatch<Action>, code: string) => {
  if (!state.checkout) return

  dispatch({ type: 'set_status', payload: 'updating' })
  const checkout = await state.client.checkout.addDiscount(state.checkout.id, code)
  dispatch({ type: 'set_checkout', payload: checkout })
}

const removeDiscount = async (state: State, dispatch: React.Dispatch<Action>) => {
  if (!state.checkout) return

  dispatch({ type: 'set_status', payload: 'updating' })
  const checkout = await state.client.checkout.removeDiscount(state.checkout.id)
  dispatch({ type: 'set_checkout', payload: checkout })
}

// _____________________________________________________________________________________________________________________

const addVariantToCart = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  variant: Queries.AddToCartContextFragment,
  quantity: number
) => {
  if (!state.checkout || !variant.shopify) return

  dispatch({ type: 'set_status', payload: 'adding' })

  const variantId = variant.shopify.variants[0].shopifyId
  const checkout = await client.checkout.addLineItems(state.checkout.id, [{ variantId, quantity }])

  dispatch({ type: 'set_checkout', payload: checkout })

  addToCardEvent({
    items: [
      {
        id: getProductId(variant.shopify),
        price: getPrice(variant.shopify),
        name: variant.shopify.title,
        category: variant.shopify.productType,
        quantity
      }
    ],
    value: getPrice(variant.shopify) * quantity,
    currency: getCurrencyCode(variant.shopify)
  })
}

const addMultipleVariantToCart = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  productList: { product: Queries.AddToCartContextFragment; quantity: number }[]
) => {
  if (!state.checkout) return
  dispatch({ type: 'set_status', payload: 'adding' })

  const products = productList
    .map(({ product, quantity }) => {
      if (!product.shopify) return null
      return { quantity, variantId: product.shopify.variants[0].shopifyId }
    })
    .filter((x): x is { variantId: string; quantity: number } => x != null)

  const checkout = await client.checkout.addLineItems(state.checkout.id, products)

  dispatch({ type: 'set_checkout', payload: checkout })

  productList.forEach(({ product, quantity }) => {
    if (!product.shopify) return

    addToCardEvent({
      items: [
        {
          id: getProductId(product.shopify),
          price: getPrice(product.shopify),
          name: product.shopify.title,
          category: product.shopify.productType,
          quantity
        }
      ],
      value: getPrice(product.shopify) * quantity,
      currency: getCurrencyCode(product.shopify)
    })
  })
}

// _____________________________________________________________________________________________________________________

const updateLineItem = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  items: ShopifyBuy.CheckoutLineItemUpdateInput[]
) => {
  if (!state.checkout) return

  dispatch({ type: 'set_status', payload: 'updating' })
  const checkout = await client.checkout.updateLineItems(state.checkout.id, items)
  dispatch({ type: 'set_checkout', payload: checkout })
}

const removeLineItem = async (state: State, dispatch: React.Dispatch<Action>, id: string) => {
  if (!state.checkout) return
  const lineItem = state.checkout.lineItems.find((line) => id === line.id)
  const currency = state.checkout.currencyCode

  dispatch({ type: 'set_status', payload: 'removing' })
  const checkout = await client.checkout.removeLineItems(state.checkout.id, [id])
  dispatch({ type: 'set_checkout', payload: checkout })

  if (!lineItem) return

  const { id: itemId, title, quantity } = lineItem
  const price = lineItem.variant?.price.amount || 0

  removeFromCardEvent({
    items: [
      {
        id: `${itemId}`,
        price: +price,
        name: title,
        quantity
      }
    ],
    value: +price,
    currency
  })
}

// Context _____________________________________________________________________________________________________________

const Context = createContext<State>({ client, status: null })

// eslint-disable-next-line @typescript-eslint/no-empty-function
const DispatchContext = createContext<React.Dispatch<Action>>(() => {})

const reducer = (state: State, action: Action): State => {
  const type: string = action.type

  switch (action.type) {
    case 'set_status': {
      return { ...state, status: action.payload }
    }
    case 'set_checkout': {
      return { ...state, checkout: action.payload, status: null }
    }
    default: {
      console.error(`Unknown action: ${type}`)
      return state
    }
  }
}

const Provider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { client, status: null })

  useEffect(() => void createCheckout(client, dispatch), [])

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </Context.Provider>
  )
}

const useShopify = () => useContext(Context)
const useShopifyDispatch = () => useContext(DispatchContext)

export default Provider

export {
  addDiscount,
  addMultipleVariantToCart,
  addVariantToCart,
  removeDiscount,
  removeLineItem,
  updateLineItem,
  useShopify,
  useShopifyDispatch
}

export const query = graphql`
  fragment AddToCartContext on PrismicProduct {
    shopify {
      title
      ...ShopifyId
      ...ShopifyPrice
      ...ShopifyCurrency

      productType

      variants {
        availableForSale
        shopifyId
      }
    }
  }
`
