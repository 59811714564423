import { Script } from 'gatsby'
import React, { useEffect } from 'react'

import Config from '../../config'
import { register as addToCart } from '../../events/addToCart'
import { register as bookingSuccess } from '../../events/appointment/bookingSuccess'
import { register as initiateBooking } from '../../events/appointment/initiateBooking'
import { register as setCustomer } from '../../events/appointment/setCustomer'
import { register as setLocation } from '../../events/appointment/setLocation'
import { register as setSlot } from '../../events/appointment/setSlot'
import { register as setTreatment } from '../../events/appointment/setTreatment'
// import { register as bookAppointment } from '../../../events/bookAppointment'
import { register as initiateCheckout } from '../../events/initiateCheckout'
import { register as newsletterSignup } from '../../events/newsletterSignup'
import { register as pageView } from '../../events/pageView'
import { register as viewProduct } from '../../events/viewProduct'
import { register as viewTreatment } from '../../events/viewTreatment'
import { register as viewTreatmentAddon } from '../../events/viewTreatmentAddon'
import { useGDPR } from '../../provider/gdpr'

// https://developers.facebook.com/docs/meta-pixel/reference
const _trackWithIndex = (index: number, args: Parameters<typeof window.fbq>): unknown => {
  if (typeof window.fbq !== 'function') {
    if (index > 10) {
      return console.error(`🚨 → 🎯 → 🇫 → %c${args[0]}`, 'font-style: italic', args.slice(1))
    }

    console.warn(`⌛ (${index * 50}ms) → 🎯 → 🇫 → %c${args[0]}`, 'font-style: italic', args.slice(1))
    return setTimeout(() => _trackWithIndex(index + 1, args), index * 50)
  }

  console.info(`🎯 → 🇫 → %c${args[0]}`, 'font-style: italic', args.slice(1))
  return window.fbq(...args)
}

const track = (...args: Parameters<typeof window.fbq>) => {
  _trackWithIndex(1, args)
}

const Facebook: React.FC = () => {
  const consent = useGDPR()
  const id = Config().shop.scripts.facebook

  useEffect(() => {
    pageView((e) => {
      const {
        detail: { category, name, id }
      } = e

      track('track', 'PageView', {
        content_type: category,
        content_name: name,
        content_ids: [`${id}`]
      })
    })

    newsletterSignup(() => track('track', 'Subscribe', { content_type: 'type', content_ids: [] }))

    viewProduct((e) => {
      const {
        detail: { type, name, id, value, currency }
      } = e

      track('track', 'ViewContent', {
        content_type: type,
        content_name: name,
        content_ids: [`${id}`],
        currency,
        value
      })
    })

    viewTreatment((e) => {
      const {
        detail: { type, name, id, value, currency }
      } = e
      track('track', 'ViewContent', {
        content_type: type,
        content_name: name,
        content_ids: [`${id}`],
        currency,
        value
      })
    })

    viewTreatmentAddon((e) => {
      const {
        detail: { type, name, id, value, currency }
      } = e
      track('track', 'ViewContent', {
        content_type: type,
        content_name: name,
        content_ids: [`${id}`],
        currency,
        value
      })
    })

    addToCart((e) => {
      const {
        detail: { value, currency, items }
      } = e

      track('track', 'AddToCart', {
        content_type: 'product_group',
        content_name: items[0].name,
        contents: items,
        content_ids: items.map(({ id }) => id),
        currency,
        value
      })
    })

    initiateCheckout((e) => {
      const {
        detail: { items, value, currency }
      } = e

      track('track', 'InitiateCheckout', {
        contents: items,
        content_ids: items.map(({ id }) => id),
        content_type: 'product_group',
        currency,
        value
      })
    })

    // Checkout Appointment

    initiateBooking(() => {
      track('trackCustom', 'initiate_booking', {} as unknown as facebook.Pixel.DPA.ViewContentParameters)
    })

    setTreatment((e) => {
      const {
        detail: { item, currency, value }
      } = e

      const params = {
        content_type: 'product_group',
        content_ids: [item.id],
        content_name: item.name,
        currency,
        value
      }

      track('trackCustom', 'select_service', params)
    })

    setLocation((e) => {
      const {
        detail: { id, name }
      } = e

      const params = {
        location_name: name,
        location_id: id
      } as unknown as facebook.Pixel.DPA.ViewContentParameters

      track('trackCustom', 'select_location', params)
    })

    setSlot((e) => {
      const {
        detail: { datetime }
      } = e

      const params = { date_time: datetime.toString() } as unknown as facebook.Pixel.DPA.ViewContentParameters

      track('trackCustom', 'select_date_time', params)
    })

    setCustomer((e) => {
      customerInformationParameters(e.detail)
        .then((data) => {
          const params = { user_data: data } as unknown as facebook.Pixel.DPA.ViewContentParameters

          return track('trackCustom', 'enter_details', params)
        })
        .catch((error) => {
          console.error(error)
        })
    })

    bookingSuccess((e) => {
      const {
        detail: { treatment, location, slot, customer }
      } = e

      customerInformationParameters(customer)
        .then((data) => {
          const params = {
            content_type: 'product_group',
            content_ids: [treatment.item.id],
            content_name: treatment.item.name,
            currency: treatment.currency,
            value: treatment.value,
            location_name: location.name,
            location_id: location.id,
            date_time: slot.datetime?.toString(),

            user_data: data
          } as unknown as facebook.Pixel.DPA.ViewContentParameters

          return track('track', 'Schedule', params)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }, [])

  if (!id || !consent?.statistic) return null

  return (
    <Script strategy="idle" id="facebook-config">
      {`
         !function(f,b,e,v,n,t,s)
         {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
         n.callMethod.apply(n,arguments):n.queue.push(arguments)};
         if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
         n.queue=[];t=b.createElement(e);t.async=!0;
         t.src=v;s=b.getElementsByTagName(e)[0];
         s.parentNode.insertBefore(t,s)}(window, document,'script',
         'https://connect.facebook.net/en_US/fbevents.js');
         fbq('init', '${id}');
        `}
    </Script>
  )
}

const customerInformationParameters = async ({
  email,
  phone,
  firstName,
  lastName
}: {
  email: string
  phone: string
  firstName: string
  lastName: string
}) => {
  return {
    em: await getSHA256Hash(email.toLocaleLowerCase()),
    ph: await getSHA256Hash(phone.replace(/^0/, '').replace('+', '').replace('', '')),
    fn: await getSHA256Hash(firstName.toLocaleLowerCase()),
    ln: await getSHA256Hash(lastName.toLocaleLowerCase())
  }
}

const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('')
  return hash
}

export default Facebook
