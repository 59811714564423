import { Configuration } from '../config'
import { defaults } from './defaults'

const configuration: Configuration = {
  ...defaults,

  shop: {
    instance: 'CN',
    countries: ['CN'],
    url: 'https://www.alpinewhite.com.cn',
    lang: { main: 'cn', enabled: ['cn'] },
    stamped: {
      apiKeyPublic: 'pubkey-Yd39IppEMY1d2453YBo66HmL04y642',
      storeUrl: 'shop.alpinewhite.ch'
    },
    sections: {
      shop: true,
      treatments: true
    },
    newsletter: 'JB8K6j',
    partners: [
      'BenuPharmacie',
      'Bipa',
      'Brack',
      'Coop',
      'CoopVitality',
      'Douglas',
      'Flaconi',
      'Galaxus',
      'Haarshop',
      'Lyko',
      'Manor',
      'PerfectHair',
      'PerfectHair',
      'Rossmann',
      'Rotpunkt',
      'ZurRose',
      'TMallGlobal',
      'JDGlobal',
      'DM'
    ],
    scripts: {
      gtm: 'GTM-P3K8685',
      baidu: '3069ac77e0c2b9543186bca9baa12889',
      hotjar: '457994',
      facebook: '2531602960492053',
      tiktok: 'C8F6G3UI9NEM6MOH09BG',
      pinterest: '2613981124848'
    },
    shopifyClient: {
      domain: 'shop.alpinewhite.com',
      accessToken: '3a2e4db4fa15a753ae7c3dea425d9bb5'
    },
    meta: {
      cn: {
        title: 'Alpine White | Teeth Whitening Strips | Whitestrips | Bleaching ',
        description:
          'Selbst für ein strahlendes Lächeln sorgen. Alpine White bietet ein komplettes Set für Reinigung, Bleaching und Pflege. Entdecke unsere Whitening Strips.',
        keywords: [
          'Whitening Strips',
          'Whitestrips',
          'Bleaching',
          'Homebleaching',
          'Whitening Kit',
          'Zahnpflege',
          'Weisse Zähne',
          'Whitening Foam',
          'Charcoal Powder'
        ]
      },
      en: {
        title: 'Alpine White | Teeth Whitening Strips | Whitestrips | Bleaching',
        description:
          'Give yourself a radiant smile with white teeth. Alpine White provides a complete kit for cleaning, bleaching and care. Discover our Whitening Strips.',
        keywords: [
          'Whitening Strips',
          'Whitestrips',
          'Bleaching',
          'Homebleaching',
          'Whitening Kit',
          'Dental Care',
          'White Teeth',
          'Charcoal Powder',
          'Whitening Foam'
        ]
      }
    }
  }
}

export { configuration }
