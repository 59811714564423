export type Language = 'de' | 'fr' | 'en' | 'pt' | 'cn'
export type PrismicLanguage = 'de-ch' | 'fr-fr' | 'en-gb' | 'pt-br' | 'zh-cn'

const languageToPrismicMap: Record<Language, PrismicLanguage> = {
  de: 'de-ch',
  fr: 'fr-fr',
  en: 'en-gb',
  pt: 'pt-br',
  cn: 'zh-cn'
}

const prismicToLanguageMap: Record<PrismicLanguage, Language> = {
  'de-ch': 'de',
  'fr-fr': 'fr',
  'en-gb': 'en',
  'pt-br': 'pt',
  'zh-cn': 'cn'
}

export { languageToPrismicMap, prismicToLanguageMap }
