import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { PrismicRichText } from '@prismicio/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import ChevronRight from '../../../../assets/icons/chevronRight.svg'
import Image from '../../../image'
import Link from '../../../link'
import RichText from '../../../richText'
import Slice from '../../../slice'
import Transition from '../../../transition'

const closeHeaderMenu = () => document.getElementById('hamburger')?.parentElement?.click()

const NavBlock: React.FC<
  Slice<
    | Queries.PrismicDomainDataBody1NavigationBlock
    | Queries.PrismicDomainDataBody2NavigationBlock
    | Queries.PrismicDomainDataBody3NavigationBlock
  >
> = ({ slice }) => {
  const { primary, items } = slice
  return (
    <div className="col-span-full mb-5 flex flex-col border-b border-b-black-10 px-5 py-5">
      <RichText data={primary?.title?.richText} className="my-2 block text-legal text-black-70" />
      {items.map((item, index) => {
        if (!item) return null
        return (
          <Menu.Item
            as={Link}
            key={index}
            link={item.link}
            onClick={closeHeaderMenu}
            className="animated-link mt-2 h-[26px] border-b-0"
          >
            <PrismicRichText field={item.title?.richText} fallback={null} />
          </Menu.Item>
        )
      })}
    </div>
  )
}

const NavImage: React.FC<
  Slice<
    | Queries.PrismicDomainDataBody1NavigationImage
    | Queries.PrismicDomainDataBody2Image
    | Queries.PrismicDomainDataBody3NavigationImage
  >
> = ({ slice }) => {
  const { primary } = slice
  return (
    <MenuItem
      as={Link}
      link={primary.link}
      className="relative col-span-full mx-2 mb-1 rounded-md border-b-0"
      onClick={closeHeaderMenu}
    >
      <div className="overflow-hidden rounded-md">
        <Image image={primary.image} className="h-auto w-full" />
      </div>
      <span className="image-overlay absolute left-0 top-0 h-full w-full rounded-md"></span>
      <RichText
        data={primary.title?.richText}
        className="text-base_sm absolute top-0 flex h-full w-full items-end px-5 py-4 font-bold text-white-default"
      />
    </MenuItem>
  )
}

const NavImageWithTitleAndLead: React.FC<
  Slice<
    | Queries.PrismicDomainDataBody1NavigationImageTitle
    | Queries.PrismicDomainDataBody2ImageWithTitleAndLead
    | Queries.PrismicDomainDataBody3NavigationImageTitle
  >
> = ({ slice }) => {
  const { primary } = slice

  const element = { link: null, ...primary }
  if (!element.link) return null

  return (
    <MenuItem as={Link} link={element.link} onClick={closeHeaderMenu} className="col-span-full border-b-0">
      <div className="mb-5 grid grid-cols-3 gap-2 px-2">
        <Image image={primary.image} className="h-auto w-full overflow-hidden rounded-md" />
        <div className="col-span-2">
          <span className="text-base_sm mb-2 mt-4 block font-bold">
            <PrismicRichText field={primary.title?.richText} fallback={null} />
          </span>
          <span className="styled-lis blockt text-base_sm text-black-70">
            <PrismicRichText field={primary.lead?.richText} fallback={null} />
          </span>
        </div>
      </div>
    </MenuItem>
  )
}

type Props = {
  title: string | null
  body: Queries.MenuItemsFragment['body1'] | Queries.MenuItemsFragment['body2'] | Queries.MenuItemsFragment['body3']
  className?: string
}

const NavItem: React.FC<Props> = ({ title, body, className: _className }) => {
  if (!title) return null

  const components = {
    navigation_block: NavBlock,
    navigation_image: NavImage,
    image: NavImage,
    navigation_image___title: NavImageWithTitleAndLead
  }

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton
            role="menuitem"
            className={twMerge(
              'text-base_sm base-transition-200 flex w-full items-center justify-between border-b border-b-black-10 px-3 font-medium',
              _className,
              open
                ? 'fixed top-0 z-[52] h-[72px] w-[inherit] bg-white-default pb-[22px] pt-[23px] text-black-default'
                : 'py-[23px] text-black-70 hover-focus:text-blue-primary'
            )}
          >
            {open && <ChevronRight width="20" height="20" className="base-transition-150 ml-3 block rotate-180" />}
            <span className={open ? 'mx-auto pr-8 pt-1' : 'pl-3 pt-1'}>{title}</span>
            {!open && <ChevronRight width="20" height="20" className="base-transition-150 mr-3 block" />}
          </MenuButton>
          <Transition
            show={open}
            as="div"
            className="absolute top-[72px] z-40 h-screen w-full max-w-screen-max bg-white-default pb-[96px]"
          >
            <MenuItems className="baseGrid relative h-full max-w-screen-xl content-start gap-0 overflow-scroll overscroll-contain pb-16 no-scrollbar">
              <Slice slices={body} components={components} />
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export const NavItemMobileAsLink: React.FC<{
  title: string | null
  link: Queries.LinkFragment | null
  className?: string
}> = ({ title, link, className }) => {
  if (!title || title === '' || !link) return null

  return (
    <div role="menuitem">
      <Link
        link={link}
        className={twMerge(
          'text-base_sm flex w-full items-center justify-between border-b border-b-black-10 px-3 font-medium',
          className,
          'py-[23px] text-black-70 hover-focus:text-blue-primary'
        )}
        onClick={closeHeaderMenu}
      >
        <span className="pl-3 pt-1">{title}</span>
        <ChevronRight width="20" height="20" className="mr-3 block" />
      </Link>
    </div>
  )
}

export default NavItem
