import { isBrowser } from '../../utils/index'

const name = 'setCustomer'

export type SetCustomerEvent = {
  firstName: string
  lastName: string
  phone: string
  email: string
}

const setCustomer = (detail: SetCustomerEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<SetCustomerEvent>(name, { detail })

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<SetCustomerEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })
export default setCustomer
