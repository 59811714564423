import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { graphql } from 'gatsby'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twJoin } from 'tailwind-merge'

import BR from '../../../assets/icons/br.svg'
import CH from '../../../assets/icons/ch.svg'
import CN from '../../../assets/icons/cn.svg'
import EU from '../../../assets/icons/eu.svg'
import type { Instance } from '../../../config'
import Config, { ShopInstance } from '../../../config'
import useSettings from '../../../hooks/useSettings'
import Button from '../../button'
import Dialog from '../../dialog'

type ShopProps = {
  instance: Instance
  last?: boolean
}

const ShopImage: React.FC<{ instance: Instance; width?: number; height?: number; className?: string }> = ({
  instance,
  width,
  height,
  className
}) => {
  switch (instance) {
    case 'BR':
      return <BR width={width} height={height} className={className} />
    case 'CH':
      return <CH width={width} height={height} className={className} />
    case 'CN':
      return <CN width={width} height={height} className={className} />
    case 'EU':
      return <EU width={width} height={height} className={className} />
    default:
      return null
  }
}

const Shop: React.FC<ShopProps> = ({ instance, last = false }) => {
  const langs = Config(instance).shop.lang
  const { flag } = Config(instance).redirect
  const { t } = useTranslation()

  const searchParams = new URLSearchParams(window.location.search)
  searchParams.append(flag, '1')

  return (
    <div
      className={twJoin(
        'p-t-md-fluid flex flex-row items-center',
        !last ? 'p-b-sm-fluid border-b border-b-black-10' : 'p-b-lg-fluid'
      )}
    >
      <ShopImage instance={instance} width={28} height={28} />
      {langs.enabled.map((lang) => {
        const slug = `?${searchParams.toString()}${window.location.hash}`
        const slugPrefix = lang === Config(instance).shop.lang.main ? '' : `${lang}/`

        return (
          <div key={lang} className="p-l-md-fluid">
            <div className="text-sub font-bold">{t(`languageSwitcher.${instance}_${lang}`)}</div>
            <Button link={`${Config(instance).shop.url}/${slugPrefix}${slug}`} type="small" className="mt-0">
              {t(`languageSwitcher.${lang}`)}
            </Button>
          </div>
        )
      })}
    </div>
  )
}

const LanguageSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const currentShop = useSettings()
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => setIsOpen(false), [])
  const openModal = useCallback(() => setIsOpen(true), [])

  return (
    <>
      <div className="flex w-[150px] cursor-pointer hover-focus:text-sky-140" onClick={openModal}>
        <ShopImage
          instance={ShopInstance}
          width={28}
          height={20}
          className="block !h-[20px] !w-[28px] min-w-fit max-w-[28px]"
        />
        <div className="flex pl-2 pr-3 font-condensed text-link_sm">
          <span>{currentShop.name}</span>
          <span className="px-[3px] font-roboto">&bull;</span>
          <span>{currentShop.language}</span>
        </div>
        <div className="flex items-center">
          <ChevronDownIcon className="block h-4 w-4" />
        </div>
      </div>
      <Dialog show={isOpen} close={closeModal}>
        <div id="dialogTitle" className="text-sub_lg font-bold">
          {t('languageSwitcher.chooseLanguage', 'Choose language and location')}
        </div>
        <div className="px-5 md:px-10">
          <Shop instance="CH" />
          <Shop instance="EU" />
          <Shop instance="BR" />
          <Shop instance="CN" last={true} />
        </div>
      </Dialog>
    </>
  )
}
export const query = graphql`
  fragment Switcher on PrismicDomainData {
    name
    language
  }
`

export default LanguageSwitcher
export { Shop, ShopImage }
