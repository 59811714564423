import { isBrowser } from '../utils/index'

const name = 'initiateCheckout'

export type InitiateCheckoutEvent = {
  items: { id: string; name: string; quantity: number }[]
  value: number
  currency: string
  quantity: number
  voucher?: string
}

const initiateCheckout = (detail: InitiateCheckoutEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<InitiateCheckoutEvent>(name, { detail })

  console.info(`🎯 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<InitiateCheckoutEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default initiateCheckout
