import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CheckCircleFilled from '../../assets/icons/checkCircleFilled.svg'
import Config from '../../config/index'
import { addDiscount, useShopify, useShopifyDispatch } from '../../provider/shopify'
import { isBrowser } from '../../utils/index'
import Transition from '../transition'

const key = Config().urlFlags.shopify.discountCode

const Discount: React.FC = () => {
  const { t } = useTranslation()
  const [voucher, setVoucher] = useState<string | null>(null)
  const state = useShopify()
  const dispatch = useShopifyDispatch()

  const close = useCallback(() => {
    setVoucher(null)
  }, [])

  useEffect(() => {
    if (!isBrowser) return

    const params = new URL(window.location.href).searchParams
    const code = params.get(key)

    if (!code) return

    void addDiscount(state, dispatch, code)
    setVoucher(code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!voucher) return null

  return (
    <Transition
      show={!!voucher}
      as="div"
      className="fade-in-fast mx-5 max-w-screen-xl py-4 md:mx-11 lg:mx-24 lg:gap-x-lg xl:mx-auto"
    >
      <div className="base-transition-150 relative flex flex-row items-center justify-between rounded border border-sunset-120 bg-sunset-default px-5 py-2.5 text-btn text-white-default shadow-snackbar">
        <div className="flex flex-row items-center justify-center">
          <CheckCircleFilled height="32" width="32" className="mr-2.5 text-black-30" />
          <div>
            <div className="text-label font-medium">
              {t('cart.voucherAdded', 'Voucher  {{ voucher }}', { voucher })}
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={close}
          name="close"
          className="base-transition-150 absolute right-4 top-2 flex h-10 w-10 items-center justify-center rounded-sm border border-transparent text-white-default hover:border-white-70 hover:text-white-70"
        >
          <XMarkIcon className="block h-5 w-5" />
        </button>
      </div>
    </Transition>
  )
}

export default Discount
