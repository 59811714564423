import { Script } from 'gatsby'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Config, { ShopInstance } from '../../config/index'
import { register as addToCart } from '../../events/addToCart'
import { register as bookingSuccess } from '../../events/appointment/bookingSuccess'
import { register as clickSelectEmployee } from '../../events/appointment/clickSelectEmployee'
import { register as initiateBooking } from '../../events/appointment/initiateBooking'
import { register as setCustomer } from '../../events/appointment/setCustomer'
import { register as setEmployee } from '../../events/appointment/setEmployee'
import { register as setLocation } from '../../events/appointment/setLocation'
import { register as setOptionalCheckoutStep } from '../../events/appointment/setOptionalCheckoutStep'
import { register as setSlot } from '../../events/appointment/setSlot'
import { register as setTreatment } from '../../events/appointment/setTreatment'
import { register as initiateCheckout } from '../../events/initiateCheckout'
import { register as newsletterSignup } from '../../events/newsletterSignup'
import { register as pageView } from '../../events/pageView'
import { register as removeFromCart } from '../../events/removeFromCart'
import { register as viewCart } from '../../events/viewCart'
import { register as viewProduct } from '../../events/viewProduct'
import { register as viewTreatment } from '../../events/viewTreatment'
import { register as viewTreatmentAddon } from '../../events/viewTreatmentAddon'
import { useGDPR } from '../../provider/gdpr'

const track = (eventParams: { [key: string]: unknown; event: string }, index = 1): unknown => {
  const eventName = eventParams.event

  if (typeof window.dataLayer?.push !== 'function') {
    if (index > 10) {
      console.error(`🚨 → 🎯 → 🇩 → %c${eventName}`, 'font-style: italic', eventParams)
      return
    }

    console.warn(`⌛ (${index * 50}ms) → 🎯 → 🇩 → %c${eventName}`, 'font-style: italic', eventParams)
    return setTimeout(() => track(eventParams, index + 1), index * 50)
  }

  console.info(`🎯 → 🇩 → %c${eventName}`, 'font-style: italic', eventParams)
  return window.dataLayer.push(eventParams)
}

const GTM: React.FC = () => {
  const consent = useGDPR()
  const { i18n } = useTranslation()
  const instance = ShopInstance
  const id = Config().shop.scripts.gtm

  useEffect(() => {
    pageView((e) => {
      const {
        detail: { name }
      } = e
      track({ event: 'page_view', page_title: name, instance, locale: i18n.language })
    })

    newsletterSignup(() => track({ event: 'sign_up', method: 'newsletter' }))

    viewProduct((e) => {
      const {
        detail: { name, id, category, value, currency }
      } = e
      track({
        event: 'view_product',
        instance,
        locale: i18n.language,
        currency,
        value,
        items: [{ item_id: id, item_name: name, item_category: category, price: value, quantity: 1 }]
      })
    })

    viewTreatment((e) => {
      const {
        detail: { name, id, category, value, currency }
      } = e

      track({
        event: 'view_treatment',
        instance,
        locale: i18n.language,
        currency,
        value,
        items: [{ item_id: id, item_name: name, item_category: category, price: value, quantity: 1 }]
      })
    })

    viewTreatmentAddon((e) => {
      const {
        detail: { name, id, category, value, currency }
      } = e

      track({
        event: 'view_treatment_addon',
        instance,
        locale: i18n.language,
        currency,
        value,
        items: [{ item_id: id, item_name: name, item_category: category, price: value, quantity: 1 }]
      })
    })

    addToCart((e) => {
      const {
        detail: { items, value, currency }
      } = e

      track({
        event: 'add_to_cart',
        instance,
        locale: i18n.language,
        currency,
        value,
        items: items.map((i) => ({
          item_id: i.id,
          item_name: i.name,
          item_category: i.category,
          price: i.price,
          quantity: i.quantity
        }))
      })
    })

    removeFromCart((e) => {
      const {
        detail: { items, value, currency }
      } = e

      track({
        event: 'remove_from_cart',
        instance,
        locale: i18n.language,
        currency,
        value,
        items: items.map((i) => ({ item_id: i.id, item_name: i.name, price: i.price, quantity: i.quantity }))
      })
    })

    viewCart((e) => {
      const {
        detail: { value, currency, voucher, items }
      } = e
      track({
        event: 'view_cart',
        instance,
        locale: i18n.language,
        value,
        currency,
        coupon: voucher,
        items: items.map((i) => {
          return { item_id: i.id, item_name: i.name, quantity: i.quantity }
        })
      })
    })

    initiateCheckout((e) => {
      const {
        detail: { value, currency, voucher, items }
      } = e

      track({
        event: 'begin_checkout',
        instance,
        locale: i18n.language,
        value,
        currency,
        coupon: voucher,
        items: items.map((i) => {
          return { item_id: i.id, item_name: i.name, quantity: i.quantity }
        })
      })
    })

    // Appointment Checkout

    initiateBooking(() => {
      track({
        event: 'initiate_booking',
        instance,
        locale: i18n.language
      })
    })

    setTreatment((e) => {
      const {
        detail: { item, currency, value }
      } = e

      track({
        event: 'select_treatment',
        instance,
        locale: i18n.language,
        value,
        currency,
        items: [{ item: item.id, item_name: item.name, price: value, quantity: 1 }]
      })
    })

    setLocation((e) => {
      const {
        detail: { id, name }
      } = e

      track({
        event: 'select_location',
        instance,
        locale: i18n.language,
        location_id: id,
        location_name: name
      })
    })

    setOptionalCheckoutStep(() => {
      track({ event: 'accept_expectation', instance, locale: i18n.language })
    })

    setSlot((e) => {
      const {
        detail: { datetime }
      } = e

      track({
        event: 'select_date_time',
        instance,
        locale: i18n.language,
        date_time: datetime?.toString()
      })
    })

    setEmployee((e) => {
      const {
        detail: { id, first_name: firstName, last_name: lastName }
      } = e

      track({
        event: 'select_employee',
        instance,
        locale: i18n.language,
        employee_id: id,
        employee_name: `${firstName} ${lastName}`
      })
    })

    setCustomer((e) => {
      const {
        detail: { firstName, lastName, phone, email }
      } = e

      hashedCustomerInformation(e.detail)
        .then((hashedUserData) => {
          return track({
            event: 'customer_data_entered',
            instance,
            user_data: {
              locale: i18n.language,
              pre_name: firstName,
              last_name: lastName,
              phone,
              mail: email,
              hashed: hashedUserData
            }
          })
        })
        .catch((error) => {
          console.error(error)
        })
    })

    bookingSuccess((e) => {
      const {
        detail: { treatment, location, slot, customer, employee }
      } = e

      hashedCustomerInformation(customer)
        .then((hashedUserData) => {
          return track({
            event: 'generate_lead',
            instance,
            locale: i18n.language,
            value: treatment.value,
            currency: treatment.currency,
            user_data: {
              pre_name: customer.firstName,
              last_name: customer.lastName,
              phone: customer.phone,
              mail: customer.email,
              hashed: hashedUserData
            },
            items: [
              {
                item_id: treatment.item.id,
                item_name: treatment.item.name,
                price: treatment.value,
                quantity: 1,
                location_id: location.id,
                location_name: location.name,
                date_time: slot.datetime?.toString(),
                employee_id: employee?.shore_id,
                employee_name:
                  employee?.first_name && employee?.last_name
                    ? `${employee?.first_name} ${employee?.last_name}`
                    : undefined
              }
            ]
          })
        })
        .catch((error) => {
          console.error(error)
        })
    })

    // Temporary Events for UI Tesing
    clickSelectEmployee(() => {
      track({ event: 'click_select_employee', instance, locale: i18n.language })
    })
  }, [i18n.language, instance])

  // Google Tag Manager is already integrade with gtag.tsx
  if (!id || !consent?.statistic) return null

  return (
    <>
      <Script strategy="idle" id="gtm-config">
        {`
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','${id}');
          `}
      </Script>
    </>
  )
}

const hashedCustomerInformation = async ({
  email,
  phone,
  firstName,
  lastName
}: {
  email: string
  phone: string
  firstName: string
  lastName: string
}) => {
  return {
    em: await getSHA256Hash(email.toLocaleLowerCase()),
    ph: await getSHA256Hash(phone.replace(/^0/, '').replace('+', '').replace('', '')),
    fn: await getSHA256Hash(firstName.toLocaleLowerCase()),
    ln: await getSHA256Hash(lastName.toLocaleLowerCase())
  }
}

const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('')
  return hash
}
export default GTM
