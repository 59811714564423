import { isBrowser } from '../../utils/index'

const name = 'setLocation'

export type SetLocationEvent = {
  id: string
  name: string
}

const setLocation = (detail: SetLocationEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<SetLocationEvent>(name, { detail })

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<SetLocationEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default setLocation
