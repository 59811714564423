import { isBrowser } from '../../utils/index'

const name = 'initiateBooking'

export type InitiateBookingEvent = null

const initiateBooking = (detail: InitiateBookingEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<InitiateBookingEvent>(name, { detail })

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<InitiateBookingEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default initiateBooking
