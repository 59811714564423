import { Script } from 'gatsby'
import React, { useEffect } from 'react'

import Config from '../../config/index'
import { register as addToCart } from '../../events/addToCart'
import { register as bookingSuccess } from '../../events/appointment/bookingSuccess'
import { register as initiateBooking } from '../../events/appointment/initiateBooking'
import { register as setCustomer } from '../../events/appointment/setCustomer'
import { register as setLocation } from '../../events/appointment/setLocation'
import { register as setSlot } from '../../events/appointment/setSlot'
import { register as setTreatment } from '../../events/appointment/setTreatment'
// import { register as bookAppointment } from '../../../events/bookAppointment'
import { register as initiateCheckout } from '../../events/initiateCheckout'
import { register as newsletterSignup } from '../../events/newsletterSignup'
import { register as pageView } from '../../events/pageView'
import { register as viewProduct } from '../../events/viewProduct'
import { register as viewTreatment } from '../../events/viewTreatment'
import { register as viewTreatmentAddon } from '../../events/viewTreatmentAddon'
import { useGDPR } from '../../provider/gdpr'

// https://ads.tiktok.com/marketing_api/docs?rid=5ipocbxyw8v&id=1701890973258754
type TikTokTrack = NonNullable<typeof window.ttq>['track']
const _trackWithIndex = (index: number, args: Parameters<TikTokTrack>): unknown => {
  if (typeof window.ttq?.track !== 'function') {
    if (index > 10) {
      return console.error(`🚨 → 🎯 → 🇹 → %c${args[0]}`, 'font-style: italic', args.slice(1))
    }

    console.warn(`⌛ (${index * 50}ms) → 🎯 → 🇹 → %c${args[0]}`, 'font-style: italic', args.slice(1))
    return setTimeout(() => _trackWithIndex(index + 1, args), index * 50)
  }

  console.info(`🎯 → 🇹 → %c${args[0]}`, 'font-style: italic', args.slice(1))
  return window.ttq.track(...args)
}

const track = (...args: Parameters<TikTokTrack>) => {
  _trackWithIndex(1, args)
}

const Tiktok: React.FC = () => {
  const consent = useGDPR()
  const id = Config().shop.scripts.tiktok

  useEffect(() => {
    pageView((e) => {
      const {
        detail: { name, id }
      } = e
      track('ViewContent', {
        content_type: 'product_group',
        content_name: name,
        content_id: `${id}`
      })
    })

    newsletterSignup(() => track('Subscribe'))

    viewProduct((e) => {
      const {
        detail: { name, id, value, currency }
      } = e

      track('ViewContent', {
        content_type: 'product',
        content_name: name,
        content_id: `${id}`,
        currency,
        value
      })
    })

    viewTreatment((e) => {
      const {
        detail: { name, id, value, currency }
      } = e

      track('ViewContent', {
        content_type: 'product',
        content_name: name,
        content_id: `${id}`,
        currency,
        value
      })
    })

    viewTreatmentAddon((e) => {
      const {
        detail: { name, id, value, currency }
      } = e

      track('ViewContent', {
        content_type: 'product',
        content_name: name,
        content_id: `${id}`,
        currency,
        value
      })
    })

    addToCart((e) => {
      const {
        detail: { items, currency }
      } = e

      items.forEach((item) => {
        track('AddToCart', {
          content_type: 'product_group',
          content_name: item.name,
          content_id: `${item.id}`,
          quantity: item.quantity,
          currency,
          value: item.price * item.quantity
        })
      })
    })

    initiateCheckout((e) => {
      const {
        detail: { items, value, currency, quantity }
      } = e

      track('InitiateCheckout', {
        content_ids: items.map(({ id }) => id),
        content_type: 'product_group',
        quantity,
        currency,
        value
      })
    })

    // Checkout Appointment

    initiateBooking(() => {
      track('ClickButton', {})
    })

    setTreatment((e) => {
      const {
        detail: { item, currency, value }
      } = e

      const params = {
        content_type: 'product_group',
        content_ids: [item.id],
        content_name: item.name,
        currency,
        value
      }

      track('selectService', params)
    })

    setLocation((e) => {
      const {
        detail: { id, name }
      } = e

      const params = {
        location_name: name,
        location_id: id
      }

      track('selectLocation', params)
    })

    setSlot(() => {
      track('Contact', {})
    })

    setCustomer((e) => {
      customerInformationParameters(e.detail)
        .then((data) => {
          const params = { user_data: data }

          return track('enterDetails', params)
        })
        .catch((error) => {
          console.error(error)
        })
    })

    bookingSuccess((_e) => {
      return track('CompleteRegistration', {})
    })
  }, [])

  if (!id || !consent?.statistic) return null

  // source: https://ads.tiktok.com/help/article?aid=10021
  return (
    <Script strategy="idle" id="tiktok-config">
      {`
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
          ttq.load('${id}');
          ttq.page();
        }(window, document, 'ttq');
      `}
    </Script>
  )
}

const customerInformationParameters = async ({
  email,
  phone,
  firstName,
  lastName
}: {
  email: string
  phone: string
  firstName: string
  lastName: string
}) => {
  return {
    em: await getSHA256Hash(email.toLocaleLowerCase()),
    ph: await getSHA256Hash(phone.replace(/^0/, '').replace('+', '').replace('', '')),
    fn: await getSHA256Hash(firstName.toLocaleLowerCase()),
    ln: await getSHA256Hash(lastName.toLocaleLowerCase())
  }
}

const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('')
  return hash
}

export default Tiktok
