import { isBrowser } from '../../utils/index'

const name = 'bookingSuccess'

export type BookingSuccessEvent = {
  treatment: {
    item: { id: string; name: string }
    value: number
    currency: string
  }
  location: {
    id: string
    name: string
  }
  slot: {
    datetime: Date
  }
  employee?: {
    shore_id: string | null
    first_name: string | null
    last_name: string | null
  }
  customer: {
    firstName: string
    lastName: string
    phone: string
    email: string
  }
}

const bookingSuccess = (detail: BookingSuccessEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<BookingSuccessEvent>(name, { detail })

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<BookingSuccessEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default bookingSuccess
