import { isBrowser } from '../../utils/index'

const name = 'setOptionalCheckoutStep'

const setCustomer = () => {
  if (!isBrowser) return

  const event = new CustomEvent(name)

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })
export default setCustomer
