import { SliceLike, SliceZone, SliceZoneLike, SliceZoneProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import React from 'react'

type Slices = SliceZoneLike<SliceLike<string>>
type Props = { slices?: Slices | object | undefined } & Omit<SliceZoneProps<unknown>, 'slices'>

const Slice: React.FC<Props> = ({ slices, components, context }) => {
  if (!slices || Object.keys(slices).length === 0) return null

  return <SliceZone slices={slices as Slices} components={components} context={context} />
}

export const query = graphql`
  fragment Slice on PrismicSlice {
    id
    slice_type
    slice_label
  }
`

export default Slice
