import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import type { SliceComponentProps } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { graphql } from 'gatsby'
import React from 'react'
import { twJoin } from 'tailwind-merge'

import Image from '../../../image'
import Link from '../../../link'
import RichText from '../../../richText'
import Slice from '../../../slice'

const NavBlock: React.FC<
  SliceComponentProps<
    | Queries.PrismicDomainDataBody1NavigationBlock
    | Queries.PrismicDomainDataBody2NavigationBlock
    | Queries.PrismicDomainDataBody3NavigationBlock,
    { closeNavigation: () => void }
  >
> = ({ slice, context }) => {
  const { primary, items } = slice
  return (
    <div className="col-span-6 flex flex-col border-b-0">
      <RichText data={primary?.title?.richText} className="my-2 block text-legal text-black-70" />
      {items.map((item, index) => {
        if (!item) return null
        return (
          <MenuItem
            as={Link}
            key={index}
            link={item.link}
            onClick={() => context.closeNavigation()}
            className="base-transition-100 mt-2 h-[26px] border-b-0 hover-focus:text-blue-primary child-span:border-blue-primary hover-focus:child-span:border-b"
          >
            <PrismicRichText field={item.title?.richText} fallback={null} />
          </MenuItem>
        )
      })}
    </div>
  )
}

const NavImage: React.FC<
  SliceComponentProps<
    | Queries.PrismicDomainDataBody1NavigationImage
    | Queries.PrismicDomainDataBody2Image
    | Queries.PrismicDomainDataBody3NavigationImage,
    { closeNavigation: () => void }
  >
> = ({ slice, context }) => {
  const { primary } = slice
  return (
    <MenuItem
      as={Link}
      link={primary.link}
      className="relative col-span-6 overflow-hidden rounded-md border-b-0"
      onClick={() => context.closeNavigation()}
    >
      <Image image={primary.image} className="h-auto w-full overflow-hidden rounded-md" />
      <span className="image-overlay absolute left-0 top-0 h-full w-full"></span>
      <RichText
        data={primary.title?.richText}
        className="text-base_sm absolute top-0 flex h-full w-full items-end px-5 py-4 font-bold text-white-default"
      />
    </MenuItem>
  )
}
const NavImageWithTitleAndLead: React.FC<
  SliceComponentProps<
    | Queries.PrismicDomainDataBody1NavigationImageTitle
    | Queries.PrismicDomainDataBody2ImageWithTitleAndLead
    | Queries.PrismicDomainDataBody3NavigationImageTitle,
    { closeNavigation: () => void }
  >
> = ({ slice, context }) => {
  const { primary } = slice

  const element = { link: null, ...primary }
  if (!element.link) return null

  return (
    <MenuItem
      as={Link}
      link={element.link}
      className="animated-image col-span-6 block border-b-0"
      onClick={() => context.closeNavigation()}
    >
      <Image image={primary.image} className="h-auto w-full overflow-hidden rounded-md" />
      <RichText data={primary.title?.richText} className="text-base_sm mb-2 mt-4 block pr-2 font-bold" />
      <RichText data={primary.lead?.richText} className="text-base_sm block pr-2 text-black-70" />
    </MenuItem>
  )
}

type Props = {
  title: string | null
  body: Queries.MenuItemsFragment['body1'] | Queries.MenuItemsFragment['body2'] | Queries.MenuItemsFragment['body3']
  openNavigation: () => void
  closeNavigation: () => void
  toggleNavigation: () => void
  state: {
    isOpen: boolean
    itemOpen: boolean
  }
}

const NavItem: React.FC<Props> = ({ title, body, openNavigation, closeNavigation, toggleNavigation, state }) => {
  if (!title) return null

  const { itemOpen, isOpen } = state

  // IMPORTANT: we habe 2 different animations:
  //  1) sliding down when menu is not open and
  //  2) fading in/out  when menu and only the content inside it is changing
  const animation = { slide: isOpen, fade: itemOpen }

  const components = {
    navigation_block: NavBlock,
    navigation_image: NavImage,
    image: NavImage,
    navigation_image___title: NavImageWithTitleAndLead
  }

  return (
    <Menu as="div" className="mb-[-1px]">
      <MenuButton
        onMouseEnter={() => openNavigation()}
        onClick={() => toggleNavigation()}
        className={twJoin(
          'text-base_sm base-transition-150 flex border-b border-b-transparent px-3 pb-[24px] pt-[23px] font-medium text-black-70',
          itemOpen && 'z-50 border-b-blue-primary text-blue-primary'
        )}
      >
        {title}
      </MenuButton>
      <section
        className={twJoin(
          'base-transition-300 absolute left-0 top-[72px] h-[365px] w-full overflow-hidden',
          animation.slide ? 'bottom-0' : 'bottom-[365px]',
          animation.fade ? 'opacity-100' : 'opacity-0',
          itemOpen ? 'z-50' : '!pointer-events-none'
        )}
      >
        <MenuItems
          static
          as="div"
          className={twJoin(
            'base-transition-300 relative mx-auto h-[365px] w-full max-w-screen-max',
            animation.slide ? 'bottom-0' : 'bottom-[365px]',
            animation.fade ? 'opacity-100' : 'opacity-0'
          )}
        >
          <div className="baseGrid mx-24 my-8 max-w-screen-xl gap-x-0.5 xl:mx-auto">
            {body.length === 3 && <div className="col-span-3" />}
            {body.length === 2 && <div className="col-span-6" />}
            <div className="contents">
              <Slice slices={body} components={components} context={{ closeNavigation }} />
            </div>
          </div>
        </MenuItems>
      </section>
    </Menu>
  )
}

export const NavItemAsLink: React.FC<{
  title: string | null
  link: Queries.LinkFragment | null
}> = ({ title, link }) => {
  if (!title || title === '' || !link) return null

  return (
    <div className="pb-[1px]">
      <Link
        link={link}
        className="text-base_sm base-transition-100 flex border-b border-b-transparent px-3 pb-[22px] pt-[23px] font-medium text-black-70 hover-focus:z-50 hover-focus:border-b-blue-primary hover-focus:text-blue-primary"
      >
        {title}
      </Link>
    </div>
  )
}

export const query = graphql`
  fragment MenuItemImageRectangular on PrismicImageField {
    alt
    gatsbyImageData(width: 307, height: 205, placeholder: BLURRED)
  }
  fragment MenuItemImageSquare on PrismicImageField {
    alt
    gatsbyImageData(width: 307, height: 307, placeholder: BLURRED)
  }

  fragment MenuItems on PrismicDomainData {
    nav_one_title
    nav_one_menu_as_link
    nav_one_link {
      ...Link
    }
    body1 {
      ... on PrismicDomainDataBody1NavigationBlock {
        ...Slice
        primary {
          title {
            ...RichText
          }
        }
        items {
          title {
            ...RichText
            text
          }
          link {
            ...Link
          }
        }
      }
      ... on PrismicDomainDataBody1NavigationImage {
        ...Slice
        primary {
          title {
            ...RichText
          }
          link {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
      ... on PrismicDomainDataBody1NavigationImageTitle {
        ...Slice
        primary {
          title {
            ...RichText
          }
          lead {
            ...RichText
          }
          link: link_target {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
    }

    nav_two_title
    nav_two_menu_as_link
    nav_two_link {
      ...Link
    }
    body2 {
      ... on PrismicDomainDataBody2NavigationBlock {
        ...Slice
        primary {
          title {
            ...RichText
          }
        }
        items {
          title {
            ...RichText
            text
          }
          link {
            ...Link
          }
        }
      }
      ... on PrismicDomainDataBody2Image {
        ...Slice
        primary {
          title {
            ...RichText
          }
          link {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
      ... on PrismicDomainDataBody2ImageWithTitleAndLead {
        ...Slice
        primary {
          title {
            ...RichText
          }
          lead {
            ...RichText
          }
          link: link_target {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
    }

    nav_three_title
    nav_three_menu_as_link
    nav_three_link {
      ...Link
    }
    body3 {
      ... on PrismicDomainDataBody3NavigationBlock {
        ...Slice
        primary {
          title {
            ...RichText
          }
        }
        items {
          title {
            ...RichText
            text
          }
          link {
            ...Link
          }
        }
      }
      ... on PrismicDomainDataBody3NavigationImage {
        ...Slice
        primary {
          title {
            ...RichText
          }
          link {
            ...Link
          }
          image {
            ...MenuItemImageRectangular
          }
        }
      }
      ... on PrismicDomainDataBody3NavigationImageTitle {
        ...Slice
        primary {
          title {
            ...RichText
          }
          lead {
            ...RichText
          }
          link: link_target {
            ...Link
          }
          image {
            ...MenuItemImageRectangular
          }
        }
      }
    }
  }
`

export default NavItem
