import '../i18n/i18n'

import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { PrismicLanguage } from '../config'
import { getI18NextLangCode } from '../i18n/'
import Gander from '../services/gander'
import { saveUTMTags } from '../utils/utmTags'
import Cookie from './_layout/cookie'
import CountryPopup from './_layout/countryPopup'
import Discount from './_layout/discount'
import Footer from './_layout/footer'
import Header from './_layout/header'
import Notice from './notice'

type Props = {
  children: React.ReactNode & {
    props: { pageContext: { lang: PrismicLanguage; id: string; uid: string; layout?: boolean } }
  }
}

const Layout: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation()

  const language = getI18NextLangCode(children.props.pageContext.lang)
  if (language && i18n.language !== language) {
    void i18n.changeLanguage(language)
  }

  useEffect(() => saveUTMTags(), [])

  const withLayout = children.props.pageContext.layout !== false

  return (
    <>
      <Gander />

      <Cookie uid={children.props.pageContext.uid} />
      <CountryPopup />
      {withLayout && <Notice />}
      {withLayout && <Header />}
      {withLayout && <Discount />}
      <main>{children}</main>
      {withLayout && <Footer />}
    </>
  )
}

// Note: Can't be in a router.ts as this is imported from build.
export const query = graphql`
  fragment Layout on PrismicDomainData {
    ...Notices
    ...Header
    ...Footer
    ...Cookie
  }
`

export default Layout
