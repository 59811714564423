import { isBrowser } from '../../utils/index'

const name = 'setSlot'

export type SetSlotEvent = { datetime: Date }

const setSlot = (detail: SetSlotEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<SetSlotEvent>(name, { detail })

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<SetSlotEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default setSlot
