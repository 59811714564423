import { Script } from 'gatsby'
import React from 'react'

import Config from '../../config'
import { useGDPR } from '../../provider/gdpr'

const Kameeleon: React.FC = () => {
  const consent = useGDPR()
  const id = Config().shop.scripts.kameeleon

  if (!id || !consent?.statistic) return null

  return (
    <>
      <Script strategy="idle" id="kameleoon-config">
        {`
          // Duration in milliseconds to wait while the Kameleoon application file is loaded
          var kameleoonLoadingTimeout = 1000;

          window.kameleoonQueue = window.kameleoonQueue || [];
          window.kameleoonStartLoadTime = new Date().getTime();

          if (! document.getElementById("kameleoonLoadingStyleSheet") && ! window.kameleoonDisplayPageTimeOut) {

            var kameleoonS = document.getElementsByTagName("script")[0];
            var kameleoonCc = "* { visibility: hidden !important; background-image: none !important; }";
            var kameleoonStn = document.createElement("style");
            kameleoonStn.type = "text/css";
            kameleoonStn.id = "kameleoonLoadingStyleSheet";

            if (kameleoonStn.styleSheet) {
              kameleoonStn.styleSheet.cssText = kameleoonCc;
            } else {
              kameleoonStn.appendChild(document.createTextNode(kameleoonCc));
            }

            kameleoonS.parentNode.insertBefore(kameleoonStn, kameleoonS);

            window.kameleoonDisplayPage = function(fromEngine) {
              if (!fromEngine) {
                window.kameleoonTimeout = true;
              }
              if (kameleoonStn.parentNode) {
                kameleoonStn.parentNode.removeChild(kameleoonStn);
              }
            };

            window.kameleoonDisplayPageTimeOut = window.setTimeout(window.kameleoonDisplayPage, kameleoonLoadingTimeout);
          }
        `}
      </Script>
      <Script strategy="idle" src={`//${id}.kameleoon.eu/kameleoon.js`} />
    </>
  )
}

export default Kameeleon
