import { Script } from 'gatsby'
import React, { useEffect } from 'react'

import Config from '../../config/index'
import { register as addToCart } from '../../events/addToCart'
import { register as initiateCheckout } from '../../events/initiateCheckout'
import { register as pageView } from '../../events/pageView'
import { register as viewProduct } from '../../events/viewProduct'
import { register as viewTreatment } from '../../events/viewTreatment'
import { register as viewTreatmentAddon } from '../../events/viewTreatmentAddon'
import { useGDPR } from '../../provider/gdpr'

// https://developers.klaviyo.com/en/docs/guide-to-integrating-a-platform-without-a-pre-built-klaviyo-integration
const track = (event: string, payload?: Record<string, unknown>, index = 0): unknown => {
  const _learnq = window._learnq || []

  if (!window._learnq) {
    if (index > 10) {
      return console.error(`🚨 → 🎯 → 🇰 → %c${event}`, 'font-style: italic', payload)
    }

    console.warn(`⌛ (${index * 50}ms) → 🎯 → 🇰 → %c${event}`, 'font-style: italic', payload)
    return setTimeout(() => track(event, payload, index + 1), index * 50)
  }

  console.info(`🎯 → 🇰 → %c${event}`, 'font-style: italic', payload)

  if (payload) {
    _learnq.push(['track', event, payload])
  } else {
    _learnq.push(['track', event])
  }
}

const Klaviyo: React.FC = () => {
  const consent = useGDPR()
  const id = Config().shop.scripts.klaviyo

  useEffect(() => {
    pageView(() => {
      track('Active on Site')
    })

    viewProduct((e) => {
      const {
        detail: { category, name, id, value }
      } = e

      track('Viewed Product', {
        Categories: [category],
        ProductName: name,
        ProductID: `${id}`,
        value
      })
    })

    viewTreatment((e) => {
      const {
        detail: { category, name, id, value }
      } = e

      track('Viewed Treatment', {
        Categories: [category],
        ProductName: name,
        ProductID: `${id}`,
        Price: value
      })
    })

    viewTreatmentAddon((e) => {
      const {
        detail: { category, name, id, value }
      } = e

      track('Viewed Treatment Addon', {
        Categories: [category],
        ProductName: name,
        ProductID: `${id}`,
        Price: value
      })
    })

    addToCart((e) => {
      const {
        detail: { items }
      } = e

      items.forEach((item) => {
        track('Added to Cart', {
          AddedItemCategories: [item.category],
          ItemNames: [item.name],
          AddedItemProductID: [`${item.id}`],
          AddedItemQuantity: item.quantity,
          $value: item.price * item.quantity
        })
      })
    })

    initiateCheckout((e) => {
      const {
        detail: { value }
      } = e

      track('Started Checkout', {
        $value: value
      })
    })
  }, [])

  if (!id || !consent?.statistic) return null

  // source: https://help.klaviyo.com/hc/en-us/articles/360020342232
  return <Script strategy="idle" src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${id}`} />
}

export default Klaviyo
