import { Configuration } from '../config'
import { defaults } from './defaults'

const configuration: Configuration = {
  ...defaults,
  shop: {
    instance: 'CH',
    countries: ['CH'],
    url: 'https://www.alpinewhite.com',
    lang: { main: 'de', enabled: ['de', 'fr', 'en'] },
    stamped: {
      apiKeyPublic: 'pubkey-Yd39IppEMY1d2453YBo66HmL04y642',
      storeUrl: 'shop.alpinewhite.ch'
    },
    sections: {
      shop: true,
      treatments: true
    },
    partners: [
      'BenuPharmacie',
      'Bipa',
      'Brack',
      'Coop',
      'CoopVitality',
      'Douglas',
      'Flaconi',
      'Galaxus',
      'Haarshop',
      'Lyko',
      'Manor',
      'PerfectHair',
      'PerfectHair',
      'Rossmann',
      'Rotpunkt',
      'ZurRose',
      'DM'
    ],
    newsletter: 'JB8K6j',
    scripts: {
      gtm: 'GTM-P3K8685',
      getklar: '3252173022',
      hotjar: '457994',
      facebook: '2531602960492053',
      tiktok: 'C8F6I8GO6OK12JDE4B2G',
      kameeleon: 'fd1jnfk7ic',
      pinterest: '2613981124848',
      klaviyo: 'MQgYCZ',
      skimLinks: '243839X1727274'
    },
    shopifyClient: {
      domain: 'shop.alpinewhite.com',
      accessToken: '3a2e4db4fa15a753ae7c3dea425d9bb5'
    },
    meta: {
      de: {
        title: 'Alpine White | Teeth Whitening Strips | Whitestrips | Bleaching ',
        description:
          'Selbst für ein strahlendes Lächeln sorgen. Alpine White bietet ein komplettes Set für Reinigung, Bleaching und Pflege. Entdecke unsere Whitening Strips.',
        keywords: [
          'Whitening Strips',
          'Whitestrips',
          'Bleaching',
          'Homebleaching',
          'Whitening Kit',
          'Zahnpflege',
          'Weisse Zähne',
          'Whitening Foam',
          'Charcoal Powder'
        ]
      },
      en: {
        title: 'Alpine White | Teeth Whitening Strips | Whitestrips | Bleaching',
        description:
          'Give yourself a radiant smile with white teeth. Alpine White provides a complete kit for cleaning, bleaching and care. Discover our Whitening Strips.',
        keywords: [
          'Whitening Strips',
          'Whitestrips',
          'Bleaching',
          'Homebleaching',
          'Whitening Kit',
          'Dental Care',
          'White Teeth',
          'Charcoal Powder',
          'Whitening Foam'
        ]
      },
      fr: {
        title: 'Alpine White | Strips Blanchiment Dentaire | Blanchiment',
        description:
          'Retrouvez un sourire rayonnant et des dents blanches. Alpine White propose une série de produits pour le nettoyage, le blanchiment et le soin des dents.',
        keywords: [
          'Kit Blanchiment Dentaire',
          'Blanchiment',
          'Bandes Blanchissantes',
          'Whitening Strips',
          'Whitestrips',
          'Soin Dentaire',
          'Charcoal Powder',
          'Whitening Foam'
        ]
      }
    }
  }
}

export { configuration }
