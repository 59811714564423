import { isBrowser } from '../../utils/index'

const name = 'setEmployee'

export type SetEmployeeEvent = {
  id: string
  first_name: string
  last_name: string
}

const setEmployee = (detail: SetEmployeeEvent) => {
  if (!isBrowser) return

  const event = new CustomEvent<SetEmployeeEvent>(name, { detail })

  console.info(`🎯 → 📆 → %c${event.type}`, 'font-style: italic', event.detail)

  window.dispatchEvent(event)
}

export const register = (fn: (e: CustomEvent<SetEmployeeEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true })

export default setEmployee
