import { Script } from 'gatsby'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'

/*
  For local testing:
  - In your browser's developer tools, open the Local Storage tab and set the following key-value pair:
    (Note: The gander-override-domain method only works for localhost.)

  Key: gander-override-domain
  Value: alpinewhite.com
*/

// AW-195 https://help.gogander.io/knowledge/set-up-gander-on-custom-site
const Gander: React.FC = () => {
  const video = useMemo(() => <div className="gander-widget"></div>, [])

  return (
    <>
      {video}
      <Script strategy="idle" id="Gander" src="https://widget.gogander.online/static/js/index.js" />
      <Helmet>
        <link href="https://widget.gogander.online/static/css/index.css" rel="stylesheet" />
      </Helmet>
    </>
  )
}

export default Gander
