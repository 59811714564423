import {
  Dialog as HeadlessDialog,
  DialogPanel as HeadlessDialogPanel,
  DialogTitle as HeadlessDialogTitle
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import Transition from './transition'

type Props = {
  show: boolean
  close: () => void
  children: React.ReactElement[]
  className?: string
}

const Dialog: React.FC<Props> = ({ show, close, children, className = '' }) => {
  return (
    <Transition show={show} as="div">
      <HeadlessDialog as="div" className="relative z-[99999]" onClose={close}>
        <div className="fixed inset-0 bg-black-40" />
        <div className="fixed inset-0 flex items-end justify-center md:items-center md:p-4">
          <Transition show={show} as="div" className="overflow-hidden rounded bg-white-default">
            <HeadlessDialogTitle
              as="div"
              className="sticky top-0 z-20 flex items-center justify-between border-b border-b-black-10 py-5 pl-5 pr-3 lg:pl-10 lg:pr-6"
            >
              {children?.filter((child: React.ReactElement) => child.props.id === 'dialogTitle')}
              <button
                type="button"
                onClick={close}
                name="close"
                className="base-transition-150 flex h-10 w-10 items-center justify-center rounded-sm border border-transparent text-blue-primary hover:border-sky-140 hover:text-sky-140"
              >
                <XMarkIcon className="block h-5 w-5" />
              </button>
            </HeadlessDialogTitle>
            <HeadlessDialogPanel
              className={twMerge(
                'md:max-h-unset relative max-h-[75dvH] w-screen overflow-y-auto md:w-[560px]',
                className
              )}
            >
              {children?.filter((child: React.ReactElement) => child.props.id !== 'dialogTitle')}
            </HeadlessDialogPanel>
          </Transition>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}

export default Dialog
