import { isBrowser } from './'

// User Data ___________________________________________________________________________________________________________
const UserDataKey = 'user_data'

type UserData = {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

export const getUserData = () => get<UserData>(UserDataKey)
export const setUserData = (userData: UserData) => set(UserDataKey, userData) // 1 month

// Functions ___________________________________________________________________________________________________________
const remove = (key: string): void => {
  localStorage.removeItem(key)
}

const set = <T>(key: string, value: T): void => {
  if (!isBrowser) {
    return
  }

  const item = { value }

  localStorage.setItem(key, JSON.stringify(item))
}

const get = <T>(key: string): T | undefined => {
  if (!isBrowser) {
    return undefined
  }

  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return undefined
  }

  const item = JSON.parse(itemStr) as { value: T }

  return item.value
}

const setWithExpiry = <T>(key: string, value: T, ttl: number): void => {
  if (!isBrowser) return

  const now = new Date()

  const item = {
    value,
    expiry: now.getTime() + ttl * 1000 // milliseconds
  }

  localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = <T>(key: string): T | undefined => {
  if (!isBrowser) return undefined

  const itemStr = localStorage.getItem(key)

  if (!itemStr) return undefined

  const item = JSON.parse(itemStr) as { value: T; expiry: number }
  const now = new Date()

  if (now.getTime() > item.expiry) {
    remove(key)

    return undefined
  }

  return item.value
}

export { get, getWithExpiry, remove, set, setWithExpiry }
