import { Transition as HeadlessTransition } from '@headlessui/react'
import React, { Fragment } from 'react'

type Props = {
  children: React.ReactNode
  show?: boolean // in case of using Transition in a Disclosure component, this variable has to be bound to the Disclosure's open state
  as?: 'div' | 'form' | 'label'
  className?: string
}

const Transition: React.FC<Props> = ({ children, show = true, as = Fragment, className = '', ...rest }) => {
  return (
    <HeadlessTransition
      appear={true}
      as={as}
      show={show}
      className={className}
      enter="base-transition-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="base-transition-200"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      {...rest}
    >
      {children}
    </HeadlessTransition>
  )
}

export default Transition
