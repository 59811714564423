import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'react-i18next'
import * as R from 'remeda'

import { getPrismicLangCode } from '../i18n'

const useSettings = () => {
  const { i18n } = useTranslation()
  const result = useStaticQuery<Queries.UseFilteredDomainQuery>(graphql`
    query UseFilteredDomain {
      filteredPrismicDomain {
        lang
        data {
          id
          ...Layout
          ...Shop
          ...Switcher
          ...PartnerMap
          ...ShippingCost

          treatmentCheckoutDisclaimer: treatment_checkout_disclaimer {
            ...RichText
          }
        }
      }
    }
  `)

  if (!result.filteredPrismicDomain) throw new Error('No settings')

  const settings = R.pipe(
    result.filteredPrismicDomain,
    R.filter((node) => node?.lang === getPrismicLangCode(i18n.language)),
    R.first()
  )

  if (settings?.data == null) throw new Error('No settings')

  return settings.data
}

export default useSettings
