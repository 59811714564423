import { useTranslation } from 'react-i18next'

import { Router } from '../../config/index'
import { getPrismicLangCode } from '../../i18n/index'

const usePageHome = () => {
  const { i18n } = useTranslation()
  const lang = getPrismicLangCode(i18n.language)

  return Router({ type: 'page_home', lang, uid: '/' })
}

export default usePageHome
