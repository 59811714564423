import React from 'react'

// Original SVG imports
import FacebookSVG from '../../assets/social/facebook.svg'
import InstagramSVG from '../../assets/social/instagram.svg'
import LinkedinSVG from '../../assets/social/linkedin.svg'
import PinterestSVG from '../../assets/social/pinterest.svg'
import TiktokSVG from '../../assets/social/tiktok.svg'
import YoutubeSVG from '../../assets/social/youtube.svg'

// Interface for SVG wrapper components
interface SVGWrapperProps {
  width?: number | string
  height?: number | string
  className?: string
}

// SVG Wrapper Components
const FacebookIcon: React.FC<SVGWrapperProps> = ({ width = '24', height = '24', className = '' }) => (
  <FacebookSVG width={width} height={height} className={className} />
)

const InstagramIcon: React.FC<SVGWrapperProps> = ({ width = '24', height = '24', className = '' }) => (
  <InstagramSVG width={width} height={height} className={className} />
)

const LinkedinIcon: React.FC<SVGWrapperProps> = ({ width = '24', height = '24', className = '' }) => (
  <LinkedinSVG width={width} height={height} className={className} />
)

const PinterestIcon: React.FC<SVGWrapperProps> = ({ width = '24', height = '24', className = '' }) => (
  <PinterestSVG width={width} height={height} className={className} />
)

const TiktokIcon: React.FC<SVGWrapperProps> = ({ width = '24', height = '24', className = '' }) => (
  <TiktokSVG width={width} height={height} className={className} />
)

const YoutubeIcon: React.FC<SVGWrapperProps> = ({ width = '24', height = '24', className = '' }) => (
  <YoutubeSVG width={width} height={height} className={className} />
)

type Icon = 'Facebook' | 'Instagram' | 'Linkedin' | 'Pinterest' | 'Tiktok' | 'Youtube'

interface SocialIconProps {
  icon: Icon | string | null
  width?: number | string
  height?: number | string
  className?: string
}

const SocialIcon: React.FC<SocialIconProps> = ({ icon, width = '24', height = '24', className = '' }) => {
  if (!icon) return null

  switch (icon) {
    case 'Facebook':
      return <FacebookIcon width={width} height={height} className={className} />
    case 'Instagram':
      return <InstagramIcon width={width} height={height} className={className} />
    case 'Linkedin':
      return <LinkedinIcon width={width} height={height} className={className} />
    case 'Pinterest':
      return <PinterestIcon width={width} height={height} className={className} />
    case 'Tiktok':
      return <TiktokIcon width={width} height={height} className={className} />
    case 'Youtube':
      return <YoutubeIcon width={width} height={height} className={className} />
    default:
      return null
  }
}

export default SocialIcon
