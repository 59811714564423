import React, { useEffect, useState } from 'react'

import { useShopify } from '../../../provider/shopify'
import Link from '../../link'
import { Icon } from '../header'

type Props = {
  item: NonNullable<Queries.MenuRestFragment['items']>[0]
  withTitle: boolean
}

const Cart: React.FC<Props> = ({ item, withTitle }) => {
  const { checkout } = useShopify()

  const countQuantity = (lineItems: ShopifyBuy.CheckoutLineItem[]) =>
    lineItems.reduce((carry, item) => item.quantity + carry, 0)
  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []))

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout])

  if (!item?.nav_link) return null

  return (
    <Link
      link={item.nav_link}
      className="base-transition-100 relative flex h-5 w-5 justify-start border-b-0 text-link_sm hover-focus:border-b-blue-primary hover-focus:text-blue-primary hover-focus:child-span:border-blue-primary lg:h-auto lg:w-auto"
    >
      {quantity > 0 && (
        <span className="absolute -top-0.5 left-[10px] flex h-3 w-3 items-center justify-center rounded-full bg-blue-primary text-center text-[8px] font-semibold text-white-default">
          {quantity}
        </span>
      )}
      {item.nav_icon && <Icon name={item.nav_icon} />}
      <span className="base-transition-200 border-b border-transparent lg:ml-1">{withTitle && item.nav_title}</span>
    </Link>
  )
}

export default Cart
