// import { Script } from 'gatsby'
import React from 'react'

import Config from '../../config/index'
import { useGDPR } from '../../provider/gdpr'

const SkimLinks: React.FC = () => {
  const consent = useGDPR()
  const id = Config().shop.scripts.skimLinks

  if (!id || !consent?.statistic) return null
  // FIXME 2023-11-13 Disable Skimlinks for now, as it completely crashed the website

  return null

  // return <Script strategy="idle" src={`https://s.skimresources.com/js/${id}.skimlinks.js`} />
}

export default SkimLinks
