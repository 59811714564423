import { Instance } from '../config'

const defaults = {
  instances: ['BR', 'CH', 'EU', 'CN'] as Instance[],
  redirect: {
    fallbackInstance: 'CH' as Instance,
    flag: 'stay'
  },
  canonical: {
    default: 'CH' as Instance,
    pt: 'BR' as Instance,
    cn: 'CN' as Instance
  },
  elfsight: { instagram: 'elfsight-app-12b0ea42-61a9-4666-9ea4-b83666f14acc' },
  ipstack: { accessToken: '297d260663b871bbe53e14955eccae3e' },
  emailjs: {
    userId: 'user_fEge3rEtuLIilxtruCGSN',
    serviceId: 'post.alpinewhite.com',
    templateId: 'contact_form'
  },
  urlFlags: {
    shopify: {
      discountCode: 'discount'
    },
    booking: {
      product: 'b_product',
      variant: 'b_variant',
      location: 'b_location'
    }
  },
  storageKey: {
    gdpr: { key: 'gdpr', ttl: 60 * 60 * 24 * 14 },
    utm: { key: 'utm', ttl: 60 * 60 * 24 * 7 },
    countryRedirect: { key: 'countryRedirect', ttl: 60 * 60 * 24 * 30 },
    productPartner: { key: 'productPartner', ttl: 60 * 60 * 24 * 3 },
    shopify: 'shopify'
  },
  general: {
    map: { apikey: 'AIzaSyDTiKgesIBStduw2q5bmjoArumrqQ165ro' },
    articles: {
      excerptLength: 100
    }
  }
}

export { defaults }
